package com.ecosave.watch.portal.services.onboarding

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import com.ecosave.watch.portal.models.onboarding.EcosaveWatchSubscriptionInvoiceDetails
import com.ecosave.watch.portal.models.onboarding.MainProductsApiResponse
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getMainProducts(): List<MainProductsApiResponse> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/payment/mainProducts")
        return when (response.status) {
            HttpStatusCode.OK -> {
                return response.body()
            }

            else -> {
                return emptyList()
            }
        }

    } catch (e: dynamic) {
        console.log(e)
    }
    return emptyList()
}

suspend fun saveProducts(selectedProductsList: List<EcosaveWatchProductEnum>): String? {
    try {
        val response: HttpResponse =
            httpClient.post("$BackendServerURL/payment/customerSetupUrl?ecosaveWatchProductEnums=${selectedProductsList.joinToString(",")}")
        return when (response.status) {
            HttpStatusCode.OK -> {
                return response.body()
            }

            else -> {
                return null
            }
        }

    } catch (e: dynamic) {
        console.log(e)
    }
    return null
}

suspend fun subscribeProducts(): List<EcosaveWatchSubscriptionInvoiceDetails> {
    try {
        val response: HttpResponse =
            httpClient.post("$BackendServerURL/payment/subscribe")
        return when (response.status) {
            HttpStatusCode.OK -> {
                return response.body()
            }

            else -> {
                return emptyList()
            }
        }

    } catch (e: dynamic) {
        console.log(e)
    }
    return emptyList()
}



