package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.onboarding.MainProductsApiResponse
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.onboarding.saveProducts
import kotlinx.browser.window
import kotlinx.coroutines.launch
import mui.icons.material.InfoOutlined
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Checkbox
import mui.material.Grid
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px

external interface ProductsTableProps : Props {
    var mainProducts: List<MainProductsApiResponse>?
}

val ProductsTable = FC<ProductsTableProps> { props ->

    val mainProducts = props.mainProducts
    var selectedProductsList by useState(mutableListOf<EcosaveWatchProductEnum>())
    val (notificationState, setNotificationState) = useState(NotificationState())
    var redirectingToStripe by useState(false)

    Grid {
        container = true
        spacing = responsive(4)
        sx {
            justifyContent = JustifyContent.center
        }
        Grid {
            item = true
            xs = 12
            TableContainer {
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            hover = true
                            TableCell {}
                            TableCell {
                                +"Product"
                            }
                            TableCell {
                                +"Price"
                            }
                            TableCell {
                                +"Trial Period"
                            }
                        }
                    }
                    TableBody {
                        mainProducts?.let { mainProducts ->
                            for (products in mainProducts) {
                                TableRow {
                                    hover = true
                                    TableCell {
                                        Checkbox {
                                            onChange = { _, checked ->
                                                val updatedSelectedProductsList = selectedProductsList
                                                if (checked) {
                                                    updatedSelectedProductsList.add(products.ecosaveWatchProductEnum)
                                                } else {
                                                    updatedSelectedProductsList.remove(products.ecosaveWatchProductEnum)
                                                }
                                                selectedProductsList = updatedSelectedProductsList.toMutableList()

                                            }
                                        }

                                    }
                                    TableCell {
                                        Box {
                                            sx {
                                                display = Display.flex
                                                gap = 5.px
                                                alignItems = AlignItems.center
                                            }
                                            +products.stripeProductName
                                            Tooltip {
                                                title = ReactNode(products.stripeProductDescription)
                                                InfoOutlined {
                                                    sx {
                                                        fontSize = 20.px
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    TableCell {
                                        +"$${products.stripeProductCost}/month"
                                    }
                                    TableCell {
                                        +"${products.trialInDays} days"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        Grid {
            item = true
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    mainScope.launch {
                        if (selectedProductsList.isNotEmpty()) {
                            redirectingToStripe = true
                            val stripeRedirectUrl = saveProducts(selectedProductsList)
                            if (stripeRedirectUrl == null) {
                                showNotification(
                                    Constants.NOTIFICATION_ERROR_MESSAGE,
                                    NotificationStatus.ERROR,
                                    notificationState,
                                    setNotificationState
                                )
                            } else {
                                window.open(stripeRedirectUrl, "_self")
                            }
                            redirectingToStripe = false
                        } else {
                            showNotification(
                                "Please select at least one product.",
                                NotificationStatus.INFO,
                                notificationState,
                                setNotificationState
                            )
                        }

                    }
                }
                +"Add Payment Details"
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    DialogSpinner {
        open = redirectingToStripe
        message = "Redirecting to stripe..."
    }
}