package com.ecosave.watch.portal.helpers.usermanagement

import kotlinx.serialization.Serializable

enum class TokenVerificationStatus {
    VERIFYING,
    VERIFIED,
    ALREADY_VERIFIED,
    INVALID_OR_EXPIRED,
    EXCEPTION
}

@Serializable
enum class OnBoardingStatus {
    PAYMENT_METHOD_PENDING,
    SUBSCRIPTIONS_PENDING,
    MANUAL_BILL_PENDING,
    UTILITY_ACCOUNT_PENDING,
    FACILITY_PENDING,
    DONE
}
