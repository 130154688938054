package com.ecosave.watch.portal.models.onboarding

import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class EcosaveWatchProduct(
    val stripeProductName: String,
    val stripeProductDescription: String?,
    val stripeProductCost: Double,
)

@Serializable
data class ProductsPaymentSummary(
    val ecosaveWatchProducts: List<EcosaveWatchProduct>,
    val totalAmount: Double,
    val paymentLinkInfo: PaymentLinkInfo,
)

@Serializable
data class PaymentLinkInfo(
    val paymentLinkUrl: String,
)

@Serializable
data class MainProductsApiResponse(
    val stripeProductName: String,
    val stripeProductCost: Double,
    val ecosaveWatchProductEnum: EcosaveWatchProductEnum,
    val trialInDays: Int,
    val stripeProductDescription: String
)

@Serializable
data class EcosaveWatchSubscriptionInvoiceDetails(
    val ecosaveWatchProductEnum: EcosaveWatchProductEnum,
    val status: String,
    val trialStart: LocalDateTime?,
    val trialEnd: LocalDateTime?,
    val totalExcludingTax: Float,
    val tax: Float,
    val total: Float
)