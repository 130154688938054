package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.StateContextProps
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.models.onboarding.EcosaveWatchSubscriptionInvoiceDetails
import com.ecosave.watch.portal.models.onboarding.MainProductsApiResponse
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.onboarding.getMainProducts
import com.ecosave.watch.portal.services.onboarding.subscribeProducts
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.styles.FacilityManagementStyles
import com.ecosave.watch.portal.styles.SubscriptionPaymentPageStyles
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import mui.icons.material.Logout
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertVariant
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.Paper
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import web.cssom.pct
import web.cssom.px

val ProductsSelectionAndPayment = FC<Props> {

    val globalState = useGlobalState()
    val navigate = useNavigate()
    var isLoading by useState(true)
    var mainProducts by useState<List<MainProductsApiResponse>>(emptyList())
    var subscriptionInvoiceDetails by useState<List<EcosaveWatchSubscriptionInvoiceDetails>>(emptyList())

    useEffectOnce {
        isLoading = true
        when (globalState.userData.onBoardingStatus) {
            OnBoardingStatus.PAYMENT_METHOD_PENDING -> {
                mainScope.launch {
                    mainProducts = getMainProducts()
                    isLoading = false
                }
            }

            OnBoardingStatus.SUBSCRIPTIONS_PENDING -> {
                mainScope.launch {
                    subscriptionInvoiceDetails = subscribeProducts()
                    isLoading = false
                }
            }

            else -> {
                navigate.invoke(PageRoutes.PAGE_NOT_FOUND.route)
            }
        }
    }

    if (isLoading) {
        Loading()
    } else {
        Box {
            className = SubscriptionPaymentPageStyles.MAIN.cssClass
            Paper {
                className = SubscriptionPaymentPageStyles.PAPER.cssClass
                Box {
                    className = SubscriptionPaymentPageStyles.INNER.cssClass
                    Box {
                        className = FacilityManagementStyles.FACILITY_LOGO_BOX.cssClass
                        ReactHTML.img {
                            css {
                                width = 32.px
                                height = 32.px
                                marginRight = 10.px
                            }
                            src = "/images/logo/company-circle-logo.png"
                        }
                        Typography {
                            variant = TypographyVariant.h5
                            +"Products Selection and Payment"
                        }
                    }
                    Box {
                        className = SubscriptionPaymentPageStyles.CONTENT.cssClass
                        if (globalState.userData.onBoardingStatus == OnBoardingStatus.PAYMENT_METHOD_PENDING) {
                            if (mainProducts.isEmpty()) {
                                paymentErrorComponent()
                            } else {
                                Alert {
                                    sx {
                                        marginBottom = 20.px
                                    }
                                    color = AlertColor.success
                                    severity = AlertColor.success
                                    variant = AlertVariant.outlined
                                    +"Your account has been created successfully."
                                }
                                ProductsTable {
                                    this.mainProducts = mainProducts
                                }
                            }

                        } else if (globalState.userData.onBoardingStatus == OnBoardingStatus.SUBSCRIPTIONS_PENDING) {

                            if (subscriptionInvoiceDetails.isEmpty()) {
                                paymentErrorComponent()
                            } else {
                                Alert {
                                    sx {
                                        marginBottom = 20.px
                                    }
                                    color = AlertColor.success
                                    severity = AlertColor.success
                                    variant = AlertVariant.outlined
                                    +"Congratulations, your subscription is active."
                                }
                                SubscribedProductsTable {
                                    invoiceDetails = subscriptionInvoiceDetails
                                }
                            }
                        }
                    }
                }
            }
            Box {
                sx {
                    height = 90.pct
                    marginLeft = 10.px
                }
                signOut(globalState)
            }
        }
    }
}

fun ChildrenBuilder.signOut(globalState: StateContextProps): Unit = Tooltip {
    Tooltip {
        title = ReactNode("Sign Out")
        IconButton {
            onClick = {
                localStorage.clear()
                globalState.updateIsAuthenticated(false)
            }
            Logout()
        }
    }
}

fun ChildrenBuilder.paymentErrorComponent() {
    Box {
        className = CommonStyles.MESSAGE_WRAPPER.cssClass
        +Constants.GENERIC_EXCEPTION_MESSAGE
        Box {
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    window.location.reload()
                }
                +"Retry"
            }
        }
    }
}