package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.StripeCustomerPortalUrl
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.decodeAccessToken
import com.ecosave.watch.portal.helpers.common.setTokensInLocalStorage
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.models.auth.TokenData
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.onboarding.EcosaveWatchSubscriptionInvoiceDetails
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.getAccessToken
import com.ecosave.watch.portal.services.getHttpClient
import com.ecosave.watch.portal.services.httpClient
import com.ecosave.watch.portal.useGlobalState
import kotlinx.browser.window
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Grid
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import react.useState
import web.cssom.JustifyContent

external interface SubscribedProductsTableProps : Props {
    var invoiceDetails: List<EcosaveWatchSubscriptionInvoiceDetails>
}

val SubscribedProductsTable = FC<SubscribedProductsTableProps> { props ->

    val invoiceDetails = props.invoiceDetails
    val globalState = useGlobalState()
    val navigate = useNavigate()
    val (notificationState, setNotificationState) = useState(NotificationState())
    var isLoading by useState(false)

    fun isEsgOnlySubscription(): Boolean {
        return ((invoiceDetails.find { it.ecosaveWatchProductEnum == EcosaveWatchProductEnum.ESG } != null) && (invoiceDetails.size == 1))
    }

    Grid {
        container = true
        spacing = responsive(4)
        sx {
            justifyContent = JustifyContent.center
        }
        Grid {
            item = true
            xs = 12
            TableContainer {
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            hover = true
                            TableCell {
                                +"Product"
                            }
                            TableCell {
                                +"Status"
                            }
                            TableCell {
                                +"Price excluding tax"
                            }
                            TableCell {
                                +"Tax"
                            }
                            TableCell {
                                +"Total Price"
                            }
                        }
                    }
                    TableBody {
                        for (invoice in invoiceDetails) {
                            TableRow {
                                hover = true
                                TableCell {
                                    +invoice.ecosaveWatchProductEnum.description
                                }
                                TableCell {
                                    if (invoice.status == "trialing") {
                                        +"Trial ends on ${invoice.trialEnd?.date}"
                                    } else {
                                        +invoice.status
                                    }
                                }
                                TableCell {
                                    +"$${invoice.totalExcludingTax}"
                                }
                                TableCell {
                                    +"$${invoice.tax}"
                                }
                                TableCell {
                                    +"$${invoice.total}"
                                }
                            }
                        }
                    }
                }
            }
        }
        Grid {
            item = true
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    window.open(StripeCustomerPortalUrl, "_blank")
                }
                +"View Subscriptions"
            }
        }
        Grid {
            item = true
            Button {
                variant = ButtonVariant.contained
                color = ButtonColor.success
                disabled = isLoading

                onClick = {
                    mainScope.launch {
                        isLoading = true
                        val tokenInfo: TokenData? = getAccessToken()

                        if (tokenInfo != null) {
                            val userInfo = decodeAccessToken(tokenInfo.accessToken)
                            setTokensInLocalStorage(tokenInfo.accessToken, tokenInfo.refreshToken)
                            httpClient = getHttpClient()

                            if (isEsgOnlySubscription()) {
                                globalState.handleUpdateUserData(
                                    globalState.userData.copy(
                                        onBoardingStatus = OnBoardingStatus.DONE,
                                        subscriptions = userInfo.subscriptions
                                    )
                                )
                                navigate.invoke(PageRoutes.DEFAULT.route)
                            } else {
                                globalState.handleUpdateUserData(
                                    globalState.userData.copy(
                                        onBoardingStatus = OnBoardingStatus.FACILITY_PENDING,
                                        subscriptions = userInfo.subscriptions
                                    )
                                )
                                navigate.invoke(PageRoutes.ONBOARDING.route)
                            }

                        } else {
                            showNotification(
                                Constants.NOTIFICATION_ERROR_MESSAGE,
                                NotificationStatus.ERROR,
                                notificationState,
                                setNotificationState
                            )
                        }
                        isLoading = false
                    }
                }
                if (isLoading) +"Redirecting..." else {
                    if (isEsgOnlySubscription()) +"Go to portal" else +"Start Onboarding"
                }
            }
        }
    }
}
