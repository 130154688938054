package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.decodeAccessToken
import com.ecosave.watch.portal.helpers.common.setTokensInLocalStorage
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.onboarding.OnboardingComponents
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.models.auth.TokenData
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.pages.FacilityManagement
import com.ecosave.watch.portal.pages.UtilityAccounts
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.getAccessToken
import com.ecosave.watch.portal.services.getHttpClient
import com.ecosave.watch.portal.services.getOnboardingStatus
import com.ecosave.watch.portal.services.httpClient
import com.ecosave.watch.portal.styles.OnboardingStyles
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Paper
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px

val Onboarding = FC<Props> {

    val stepper by useState(OnboardingComponents.values())
    var activeComponent by useState(OnboardingComponents.FACILITY_REGISTRATION)
    var isLoading by useState(true)
    val globalState = useGlobalState()
    val navigate = useNavigate()
    val (notificationState, setNotificationState) = useState(NotificationState())

    fun navigateToUtilityAccountRegistration() {
        stepper.first { it == OnboardingComponents.FACILITY_REGISTRATION }.stepCompleted = true
        activeComponent = OnboardingComponents.UTILITY_ACCOUNT_REGISTRATION
    }

    useEffectOnce {
        mainScope.launch {
            isLoading = true

            val onboardingStatus = getOnboardingStatus()

            if (onboardingStatus != null) {
                when (onboardingStatus) {
                    OnBoardingStatus.FACILITY_PENDING -> {
                        activeComponent = OnboardingComponents.FACILITY_REGISTRATION
                    }

                    OnBoardingStatus.UTILITY_ACCOUNT_PENDING, OnBoardingStatus.MANUAL_BILL_PENDING -> {
                        navigateToUtilityAccountRegistration()
                    }

                    else -> {
                        navigate.invoke(PageRoutes.PAGE_NOT_FOUND.route)
                    }
                }
            }
            isLoading = false
        }
    }

    useEffect(globalState.userData) {
        if (globalState.userData.onBoardingStatus == OnBoardingStatus.UTILITY_ACCOUNT_PENDING) {
            navigateToUtilityAccountRegistration()
        }
    }

    if (isLoading) {
        Loading()
    } else {
        Box {
            sx {
                width = 100.pct
                height = 100.pct
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
                gap = 10.px
            }
            Box {
                OnboardingStepper {
                    activeStep = activeComponent.ordinal
                    this.stepper = stepper
                }
            }
            Box {
                sx {
                    display = Display.flex
                    gap = 10.px
                    width = 80.pct
                    height = 90.pct
                    justifyContent = JustifyContent.spaceBetween
                }
                Paper {
                    className = OnboardingStyles.INFORMATION_CENTER.cssClass
                    Box {
                        className = OnboardingStyles.INNER.cssClass
                        Box {
                            sx {
                                display = Display.flex
                                flexDirection = FlexDirection.column
                                justifyContent = JustifyContent.flexStart
                                width = 100.pct
                                height = 100.pct
                                gap = 20.px
                            }
                            Box {
                                className = OnboardingStyles.LOGO_BOX.cssClass
                                ReactHTML.img {
                                    css {
                                        width = 32.px
                                        height = 32.px
                                        marginRight = 10.px
                                    }
                                    src = "/images/logo/company-circle-logo.png"
                                }
                                Typography {
                                    variant = TypographyVariant.h5
                                    when (activeComponent) {
                                        OnboardingComponents.FACILITY_REGISTRATION -> {
                                            +OnboardingComponents.FACILITY_REGISTRATION.description
                                        }

                                        OnboardingComponents.UTILITY_ACCOUNT_REGISTRATION -> {
                                            +OnboardingComponents.UTILITY_ACCOUNT_REGISTRATION.description
                                        }
                                    }
                                }
                            }


                            when (activeComponent) {
                                OnboardingComponents.FACILITY_REGISTRATION -> {
                                    FacilityManagement()
                                }

                                OnboardingComponents.UTILITY_ACCOUNT_REGISTRATION -> UtilityAccounts()
                            }

                        }
                    }
                }
                Box {
                    signOut(globalState)
                }
            }

        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}