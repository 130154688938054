package com.ecosave.watch.portal.components

import com.ecosave.watch.portal.components.navigation.AppBarComponent
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.useGlobalState
import mui.material.Box
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.router.Navigate
import react.router.Outlet
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.Padding
import web.cssom.em
import web.cssom.px

val MainLayout = FC<Props> {

    val stateContext = useGlobalState()

    when (stateContext.userData.onBoardingStatus) {
        OnBoardingStatus.DONE -> {
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 90.px
                }
                Box {
                    AppBarComponent()
                }
                Box {
                    sx {
                        padding = Padding(0.em, 3.em, 3.em, 3.em)
                    }
                    Typography {
                        sx {
                            fontWeight = FontWeight.bold
                            marginBottom = 20.px
                        }
                        align = TypographyAlign.center
                        variant = TypographyVariant.h5
                        +stateContext.pageTitle
                    }
                    Outlet {}
                }
            }
        }

        OnBoardingStatus.PAYMENT_METHOD_PENDING, OnBoardingStatus.SUBSCRIPTIONS_PENDING -> {
            Navigate {
                to = PageRoutes.SUBSCRIPTION_PAYMENT.route
                replace = true
            }
        }

        OnBoardingStatus.FACILITY_PENDING, OnBoardingStatus.UTILITY_ACCOUNT_PENDING, OnBoardingStatus.MANUAL_BILL_PENDING -> {
            Navigate {
                to = PageRoutes.ONBOARDING.route
                replace = true
            }
        }
    }
}
